
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: linear-gradient(to right, #e0a040, #ffffff);
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 8px solid #fff; /* White */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); } 
}

.loading-spinner{
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 8px solid orange; /* White */
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.5s linear infinite; 
}