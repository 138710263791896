
.play-button-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .thumbnail-item:hover .play-button-overlay {
    opacity: 1;
  }
  
  .play-button-overlay i {
    font-size: 40px;
    /* color: #fff; */
    color: orange;
    cursor: pointer;
  }
  

.video-player {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .video-player video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* Tablet view */
@media (max-width: 768px) {
  .play-button-overlay {
    display: none;
  }
}
