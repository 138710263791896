.right-slide-modal  {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 420px;
    margin: 0;
    height: 100vh;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .right-slide-modal.show .modal-dialog {
    transform: translateX(0);
  }
  
  .right-slide-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
  }