.login-container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  padding-left: 30px;
  padding-right: 30px;
}

.company-details {
  margin-bottom: 20px;
}

.login-form {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .login-container {
    width: 100%;
    padding: 100px;
  }
  .login-form {
    margin-bottom: 20px;
  }
  .card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .login-container {
    padding: 30px;
  }
}

.form-container {
  /* height: 100vh; */
  margin-top: 20px;
  margin-bottom: 100px;
}

.video-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

.tag {
  display: flex;
  align-items: center;
  background-color: rgb(247, 148, 29);
  color: white;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  font-size: 14px;
}

.tag button {
  background: none;
  border: none;
  color: white;
  margin-left: 5px;
  cursor: pointer;
  font-size: 14px;
}

.video-preview {
  margin-bottom: 20px;
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  /* gap: 2px; */
  margin-bottom: 20px;
  width: 100%;
  /* max-width: 1020px; */
  overflow-x: auto;
  cursor: grab;
  overflow-y: hidden;
  border: 2px dotted rgb(44, 44, 44);
  padding: 10px;
  border-radius: 5px; 
  /* height: 60vh; */
}
.video-preview:active {
  cursor: grabbing;
}

.video-preview video {
  /* max-width: 100%; */
  height: 50vh;
}

.video-upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.video-preview .video-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;
  margin: 30px;
}

.video-preview .video-container video {
  position: relative;
  max-width: 320px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.video-preview .video-container .button-container {
  position: relative;
  /* right: 0; */
}

input[type="file"] {
  display: block;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.video-info {
  margin-bottom: 20px;
  font-weight: bold;
}
